@font-face {
  font-family: 'Hollow Space';
  src:
    url('./Fonts/HollowSpace.ttf') format('truetype');
}

@font-face {
  font-family: 'BADABB';
  src: url('./Assets/fonts/BADABB__.TTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kids';
  src: url('./Assets/fonts/Kids\ Magazine.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helsinki';
  src: url('./Assets/fonts/helsinki.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Humanist';
  src: url('./Assets/fonts/Humanist-777-Black-Condensed.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap');

* {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Kids", sans-serif;
  font-weight: 400 !important;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  text-shadow: .3125rem .3125rem #000;
}

dialog {
  font-family: "Inter", sans-serif !important;
}

.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  margin: 0 auto;
}

html,
body {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  background-color: #1B0200;
}

.header-designer-ultra-smart-bb-gg {
  color: white;
  font-size: 40px;
  text-align: center;
}

@media (max-width:800px) {
  .header-designer-ultra-smart-bb-gg {
    font-size: 30px;
  }
}

/* Add this in your CSS file (e.g., App.css) */
.hide-small {
  display: inline;
  /* Show by default */
}

@media (max-width: 475px) {
  .hide-small {
    display: none;
    /* Hide on screens smaller than 475px */
  }
}

/* nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
} */

.home-container {
  background-image: url("./Assets/horsewithfuckingbuckses.png");
  background-size: cover;
  background-position: right center;
  background-repeat: no-repeat;
  min-height: 100vh;
  /* Takes the full height of the viewport */
}

@media (max-width: 800px) {
  .home-container {
    background-image: url("./Assets/horsewithfuckingbuckses2.png");
    background-position: bottom right -100px;
    /* Position centrally */
    background-size: 130% auto;
    /* Scale to 90% of width, maintain aspect ratio */
    background-repeat: no-repeat;
    min-height: 90vh;
    width: 100vw;
  }
}


.secondary-button {
  background-color: #ff6b6b;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-top: 20px;
}

.secondary-button:hover {
  background-color: #ff3d3d;
}

/* 
.navbar-menu-container {
  display: none;
} */

/* Add this to your CSS file */

.navbar-links-container {
  display: flex;
  align-items: center;
}

.social-media-buttons {
  display: flex;
  gap: 10px;
  margin-left: auto;
  justify-content: end;
  /* Pushes social media buttons to the right */
}

.social-media-buttons a {
  color: #000;
  /* Default color */
  font-size: 24px;
  /* Adjust size as needed */
  text-decoration: none;
}

.social-media-buttons a:hover {
  color: #0073e6;
  /* Change color on hover */
}

.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}

.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 0;
  flex-direction: column;
  align-items: flex-start;
  /* Centers items horizontally */
  justify-content: flex-start;
  /* Centers items vertically */
  height: 100vh;
}

.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
}

.home-image-section {
  max-width: 600px;
  flex: 1;
}

.home-text-section {
  transform: translateY(-3%);
  margin: 0vw 10vw;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.primary-heading {
  outline: #000;
  font-size: clamp(2rem, 5vw, 4rem);
  color: white;
  /* line-height: 5rem; */

}

.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
  text-align: start;
  /* Centers the text */
  margin-bottom: 20px;
  /* Adds space between paragraphs */
  line-height: 1.5;
}


.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}

.secondary-button:hover {
  background-color: #e48f0f;
}


@media(max-width: 800px) {}




.primary-subheading {
  font-weight: 700;
  color: #ffffff;
  font-size: 1.15rem;
}



.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}

.contact-page-wrapper h1 {
  max-width: 900px !important;
}

.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer-wrapper {
  margin: 5vh 7vw !important;
  display: flex;
  margin-top: 10rem;
  color: white;
}

.support {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  max-width: 300px;
}

.footer-logo-container {
  max-width: 492px;
}

.footer-icons {
  margin-top: 2.5rem;
}

.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}

.social-media-buttons img {
  display: flex;
  flex-direction: row;
  width: 60px;
}


.footer-text div {
  font-style: italic;
  font-family: 'ABeeZee';
  font-size: 24px;
}

@media (max-width:800px) {
  .footer-text div {
    font-size: 14px;
  }
}

.footer-section-one {
  margin-right: 15vw;
}

.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}

.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}

.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}

.testimonial-section-bottom {
  margin: 2rem auto;
}

.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}

.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}

.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}

.work-section-wrapper {
  background-color: #56B4C6;
  margin-top: 15rem;
  padding: 7vw;
}

.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}

.work-section-top h1 {
  max-width: 700px !important;
}

.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}

.work-section-info h2 {
  margin: 1rem 0rem;
}

.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }

  .primary-button {
    font-size: 1rem;
  }

  .home-bannerImage-container {
    max-width: 600px;
  }
}

.logo {
  font-size: 5vw;
  margin-bottom: 40px;
}

@media (max-width: 800px) {
  .logo {
    font-size: 15vw;
  }

  .nav-logo-container {
    max-width: 140px;
  }

  .navbar-links-container {
    display: flex;
  }


  .navbar-menu-container {
    display: flex;
  }

  .home-bannerImage-container {
    max-width: 450px;
  }

  .home-banner-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }


  .home-image-section {
    width: 100%;
    max-width: 400px;
  }

  .primary-heading {
    text-align: center;
    max-width: 90%;
  }

  .primary-text {
    text-align: center;
    max-width: 80%;
  }

  .home-text-section {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }

  .primary-subheading {
    text-align: center;
  }

  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }

  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }

  .work-section-wrapper {
    margin-top: 5rem !important;
  }

  .work-section-bottom {
    margin-top: 1rem !important;
  }

  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }

  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }

  .footer-wrapper {
    flex-direction: column;
  }

  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }

  .footer-section-columns {
    margin: 1rem 0rem;
  }

  .App {
    max-width: 100vw;
  }
}

@media (max-width: 600px) {

  .home-bannerImage-container {
    display: none;
  }
}