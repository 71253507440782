.about-section-container {
    padding: 10vh 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* Flex wrap ensures content is responsive */
}

.about-section-text-container .header-designer-ultra-smart-bb-gg {
    font-size: 40px;
    color: white;
    margin-bottom: 36px;
    text-align: start;

    @media (max-width:1200px) {
        font-size: 20px;
        margin-bottom: 36px;
        text-align: center;
    }
}

.about-section-text-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin-left: 50px;
    z-index: 0;

    @media (max-width:800px) {
        align-items: center;
        justify-content: center;
    }
}

.about-section-text-container p {
    color: white;
    font-size: 26px;
    font-family: "Humanist", sans-serif;
    font-style: italic;
    width: 45vw;
    line-height: 1.6;
    margin-bottom: 80px;
    @media (max-width:800px) {
        margin-bottom: 0px;
    }    
}

.about-section-image-container {
    flex: 1;
    margin-left: 90px;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    z-index: 0;
}

.about-section-image-container img {
    width: 40vw;
    min-width: 490px;
    max-width: 100%;
    /* Ensures image doesn't exceed container size */
    height: auto;
    border-radius: 15px;
    box-shadow: 0px 0px 10px 6px #FF6F1E;
}

.buy-button-container {
    display: flex;
    flex-direction: column;
    gap:20px;
    margin-right: 1rem;
    @media (max-width:800px) {
        margin-right: 0;
    }
}

.buy-button2 {
    position: relative;
    display: inline-block;
    padding: 1.8rem 5rem;
    /* Adjust size */
    font-size: clamp(16px, 4vw, 20px);
    /* Responsive text size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: linear-gradient(90deg, #862702, #E85D04);
    border: 1px solid #F6C060;
    border-radius: 8px; 
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 10px 3px #FF6F1E;
    max-width: 400px;
}
.marketplace-button{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 5rem 1rem 5rem;
    /* Adjust size */
    font-size: clamp(16px, 4vw, 20px);
    /* Responsive text size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    /* background: linear-gradient(90deg, #E85D04,#862702); */
    background: transparent;
    border: 1px solid #F6C060;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 10px 3px #FF6F1E;
    max-width: 400px;
}
/* Hover state */
.buy-button2:hover {
    box-shadow: 0px 0px 10px 6px #FF6F1E;
}

/* Active state */
.buy-button2:active {
    background-color: #d65003;
    transform: scale(0.95) translate(4px, 4px);
}

/* Disabled state */
.buy-button2:disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    cursor: not-allowed;
}

.about-background-image-container {
    position: absolute;
    left: -150px;
    z-index: -2;
}



@media (max-width: 1200px) {
    .about-section-container {
        flex-direction: column;
        padding: 5vh 0;
        align-items: center;
        justify-content: center;
    }

    .about-section-text-container {
        width: 80vw;
        margin-left: 0;
        margin-top: 2rem;
        text-align: center;
        align-items: center;

        /* Center align text on smaller screens */
    }

    .about-section-text-container h1 {
        font-size: 32px;
        text-shadow: 3px 3px black;
    }

    .about-section-text-container p {
        width: 100%;
        font-size: 18px;

        /* Adjust text size */
    }

    .about-section-image-container {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    .about-section-image-container img {
        min-width: unset;
        width: 70vw;
        max-width: 400px;
    }

    .about-buttons-container {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .buy-button2 {
        width: 90vw;
        padding: 1rem 4rem;
        margin: 1rem 0 0.2rem 0;
        font-size: clamp(16px, 4vw, 20px);
    }

    .about-background-image-container {
        display: none;
        /* Hide background image on mobile */
    }
}