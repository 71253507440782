.roadmap-container {
    margin: 0 1vw;
    margin-bottom: 130px;
    text-align: center;
    background-color: unset;
    /* Matching the yellow background */
}

.roadmap-title {
    font-size: 80px;
    color: white;
    margin-bottom: 50px;
}

.roadmap-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px;
    justify-items: center;
}



.roadmap-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    text-align: center;
}

.roadmap-item img {
    width: 80%;
    height: auto;
    border-radius: 8px;
}

.roadmap-item p {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.arrow {
    width: 10vw;
    height: auto;
    transform: translateY(30%);
    /* Add arrow positioning styles if needed */
}

#up {
    transform: translateY(-50%) rotate(270deg);
}

#down {
    transform: translateY(50%) rotate(90deg);
}

#right {
    transform: translateY(50%) rotate(0);
}

#left {
    transform: translateY(-50%) rotate(180deg);
}

@media (max-width: 800px) {
    .roadmap-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}