/* General Container Styling */
.available-section-container {
    padding: 20vh 0;
    margin: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* Responsive Grid for SVG Items */
.svg-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 3 columns */
    grid-template-rows: repeat(2, auto); /* 3 rows */
    gap: 40px; /* Space between items */
    margin: 5vh 2.5vw;
    justify-content: center;
}

/* Individual SVG Item Styling */
.svg-item {
    background-color: #1B0200; /* Dark background */
    border: 2px solid #F6C060; /* Orange border */
    border-radius: 50px; /* Rounded corners */
    padding: 1vw 2vw; /* Internal padding */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 12px 4px #FF6F1E; /* Glowing orange shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

/* SVG Image Styling */
.svg-item img {
    width: 13vw; /* Flexible width for SVGs */
    height: auto; /* Maintain aspect ratio */
    max-width: unset !important; /* Override conflicting max-widths */
}

/* Heading Styling */
h1 {
    color: white;
    font-size: 32px !important; /* Heading size */
    margin-bottom: 20px;
    text-align: center; /* Centered text */
}

/* Responsive Design */
@media (max-width: 800px) {
    .available-section-container {
        padding: 10vh 0; /* Reduce padding for smaller screens */
    }

    .svg-grid {
        margin: 5vh; /* Adjust margin for better fit */
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
        grid-template-rows: auto; /* Dynamic rows */
    }

    .svg-item {
        padding: 2vw; /* Increase padding for touch-friendly sizes */
        border-radius: 30px; /* Slightly less rounded on smaller screens */
    }

    .svg-item img {
        width: 35vw !important; /* Adjust SVG size */
    }
}
