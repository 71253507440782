@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');

.what-section-container {
    background-color: #56B4C6;
    background-image: url('../Assets/whataa.png');
    background-size: cover;
    background-position: right center;
    background-repeat: no-repeat;
    padding: 10vh;
    margin: 0;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.vibee-text {
    text-shadow: 2px 4px black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 1150px;
    text-align: center;
    font-size: clamp(1rem, 3vw, 1.5rem);
    color: #ffffff;
    z-index: 2;
    font-family: "Humanist", sans-serif !important;
    /* Ensure correct font family */
}

.vibee-text div.text {
    font-family: "Humanist", sans-serif !important;
    font-weight: 500 !important;
    font-style: normal;
    font-size: 26px;
    max-width: 800px;
}

.vibee-text h1 {
    color: white;
    text-align: center;
    font-size: clamp(1.5rem, 5vw, 3rem);
}

.what-section-container * {
    position: relative;
    z-index: 2;
}

@media (max-width: 800px) {
    .what-section-container {
        flex-direction: column;
        padding: 10vh 2vh;
        background-image: url('../Assets/whataa.png');
        background-size: cover;
        background-position: calc(100% + 100px) center;
        /* Moves the image 20px to the left */
        background-repeat: no-repeat;

        header-designer-ultra-smart-bb-gg {
            font-size: 16px !important;
        }
    }

    .vibee-text {
        width: 100%;
    }
}