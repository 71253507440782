/* Default button style */
.buy-button {
    position: relative;
    margin: 0 10vw 10px 0;
    z-index: 1;
    display: inline-block;
    padding: 29px 84px;
    /* Adjust size */
    font-size: clamp(16px, 4vw, 30px);
    /* Adjust text size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    /* Text color */
    background: linear-gradient(90deg, #862702, #E85D04);
    /* Button color */
    border: 1px solid #F6C060;
    border-radius: 8px;
    /* Rounded corners */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, translate 0.3s ease;
    box-shadow: 0px 0px 10px 3px #FF6F1E;
    left: 30vw;
    transform: rotate(-6deg);
}

.buy-button:hover {
    transform: scale(1.1) rotate(-6deg);
    box-shadow: 0px 0px 10px 6px #FF6F1E;
}

/* Disabled state */
.buy-button:disabled {
    background-color: #d3d3d3;
    /* Light grey color for disabled state */
    color: #a9a9a9;
    /* Darker grey text */
    cursor: not-allowed;
    transform: none;
    /* Remove hover effects */
}

.buy-button:active {
    background-color: rgb(243, 110, 48);
    /* Even darker color when pressed */
    transform: scale(0.98);
    /* Slight shrink effect */
}

@media (max-width: 800px) {
    .buy-button {
        padding: 10px 100px;
        font-size: 12px;
        margin: 0 0 10px 0;
        transform: rotate(-6deg) translateY(-90px) translateX(10px);
        left: unset;
    }

    .buy-button#home {
        padding: 15px 40px;
        width: 300px;
        background-color: #ED5A02;
        transform: translate(0px, -90px) rotate(-6deg);

    }

    .buy-button:hover {
        transform: translate(4px, 4px);
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
    }

    .buy-button:active {
        background-color: rgb(243, 110, 48);
        /* Even darker color when pressed */
        transform: scale(0.98);
        /* Slight shrink effect */
    }

}