.hermy-movement {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background: url('../Assets/hermymovement_black.png') no-repeat;
    background-size: cover;
    background-position: right center;
    background-attachment: scroll;
    height: 100vh;
    min-height: 100vh;
    /* Full screen */
    position: relative;
}

.hermy-content {
    margin-top: 60px;
    color: white;
    padding: 20px;
    position: relative;
    /* Ensure it appears above the overlay */
    z-index: 0;
    background-color: rgba(0, 0, 0, 0);
    /* Slightly transparent background for text */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hermy-content div {
    font-size: 44px;
    margin-bottom: 20px;
    text-shadow: 2px 2px black;
}

.hermy-content p {
    max-width: 980px;
    font-size: 32px;
    margin-bottom: 20px;
}

/* .social-icons {} */

.social-icons img {
    width: 80px;
    height: auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 30px;
    transition: transform 0.3s ease, color 0.3s ease;
    margin: 10px;

    @media (max-width:440px) {
        width: 60px;
    }
}

.social-icons img:hover {
    transform: translateY(-5px);
}

.empty-block {
    height: 20vw;
}

@media (max-width:1300px) {
    .hermy-content div {
        font-size: 20px;
    }

    .hermy-content p {
        font-size: 12px;
    }
}