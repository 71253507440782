.navbar-links-container a {
    color: white;
    text-decoration: none;
    transition: color 0.3s, transform 0.3s;
}

.navbar-links-container a:hover {
    color: #ddd;
    transform: translateY(-5px);
}

.social-media-buttons .social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    color: white;
    font-size: 30px;
    transition: transform 0.3s ease;
}

.social-media-buttons .social-icon:hover {
    transform: scale(1.1);
}

.social-media-buttons svg {
    width: 30px;
    height: 30px;
}

.drawer-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transform: translateY(-5px);
}

nav {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90px;
    padding: 10px 50px;
    transition: backdrop-filter 0.6s ease, background 0.6s ease;
}

.navbar-menu-container {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
}

@media (max-width: 800px) {
    .navbar-menu-container {
        right: 10px;
    }

    .links {
        display: none;
    }
}

.nav-logo-container img {
    height: 60px;
}

.navbar-links-container a {
    margin-right: 20px;
    text-decoration: none;
    color: white;
    font-weight: bold;
    text-shadow: 1px 1px 2px black;
}

.navbar-links-container a:hover {
    color: #ff8741;
}

.social-media-buttons a {
    margin-left: 10px;
    color: white;
    transition: transform 0.2s ease-in-out;
}

.social-media-buttons a:hover {
    transform: scale(1.1);
}

.navbar-menu-container svg {
    cursor: pointer;
    color: white;
    font-size: 30px;
}

.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90px;
    padding: 10px 50px;
    background: linear-gradient(to bottom, #EE5A0222, rgba(0, 0, 0, 0));
    transition: transform 0.3s ease-in-out;
}

.navbar.visible {
    transform: translateY(0);
}

.navbar.hidden {
    transform: translateY(-100%);
}