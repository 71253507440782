.pfp-section-container {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    padding: 5vw;
    color: white;
    justify-content: center;
}

.pfp-image-container {
    width: 40vw;
    height: auto;
    margin-right: 5vw;
}

.pfp-image-container img {
    border-radius: 30px;
    width: 40vw;
    height: auto;
}

.buy-button3 {
    position: relative;
    display: inline-block;
    padding: 1.5rem 3rem;
    /* Adjust size */
    font-size: clamp(12px, 3vw, 25px);
    /* Responsive text size */
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: #fff;
    background: linear-gradient(90deg, #862702, #E85D04);
    border: 1px solid #F6C060;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 0px 12px 6px #E85D04;
    max-width: 800px;
}

.pfp-text-container {
    font-size: 2vw;
    text-align: left;
    width: 33vw;

    p {
        font-family: "Humanist", sans-serif;
    }
}

.pfp-text-container p {
    font-family: "Humanist", sans-serif;
}

@media(max-width:800px) {

    .pfp-section-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .pfp-text-container {
        width: 90vw;
        text-align: center;
        font-size: 2vh;
    }

    .pfp-image-container {
        width: 80vw;
        height: auto;
        margin: 5vw;
    }

    .pfp-image-container img {
        border-radius: 30px;
        box-shadow: 4px 4px 0px black;
        width: 80vw;
        height: auto;
    }

}