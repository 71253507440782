.how-to-buy-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10vh 0 calc(30vmin * 1.4142) 0;
    text-align: center;
    z-index: 2;
    /* Ensure the content is above the glow */
}

.glow-background {
    background: url('../Assets/glow.png') no-repeat center center;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    /* Adjust transparency */
    pointer-events: none;
    /* Ensure it doesn’t affect click interactions */
    z-index: 0;
    /* Make sure this is behind the content */
}
/* .how-to-buy-container{
    background-image: url('../Assets/solanadrops.png');
    background-size: cover;
} */
.heading {
    color: white;
    font-size: 3rem;
    margin-bottom: 40px;
}

.steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.step-card {

    color: white;
    padding: 0;
    height: auto;
    width: auto;
    text-align: left;
    border-radius: 15px;
    transition: transform 0.3s ease;
    cursor: pointer;
    position: relative;
    transform-origin: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.step-card img {
    width: 37vh;
}



@media (max-width: 1000px) {}

@media (max-width:800px) {
    .steps-container {
        max-width: 75vw;
    }
}

.step-0 {
    transform: translateY(0);
}

.step-1 {
    transform: translateX(-2vw) translateY(10vw);
}

.step-2 {
    transform: translateY(20vw);
}

.step-3 {
    transform: translateY(30vw);
}

.step-0:hover {
    transform: translateY(0) scale(1.05);
}

.step-1:hover {
    transform: translateX(-2vw) translateY(10vw) scale(1.05);
}

.step-2:hover {
    transform: translateY(20vw) scale(1.05);
}

.step-3:hover {
    transform: translateY(30vw) scale(1.05);
}

@media(min-width: 1200px) {
    .how-to-buy-container {
        padding: 10vh 0 calc(48vmin * 1.4142) 0;
    }
}