.jupiter-section-container {
    background-color: black;
    padding: 10vw 0 10vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Inter", sans-serif !important;
}

.jupiter-head {
    color: white !important;
    text-align: center;
    width: 40vw;
}

@media (max-width:800px) {
    .jupiter-heading {
        font-size: 10vw;
    }
}

.jupiter-heading {
    font-size: 5vw;
}

.jupiter-subheading {
    color: white;
    font-size: 2vh !important;
}


.terminal-container {
    min-height: 568px;
}